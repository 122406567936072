




























import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class FilterAndSearch extends Vue {
  public name = "FilterAndSearch";

  private focused = false;

  public $refs!: {
    searchInput: HTMLInputElement;
  };

  @Prop({ type: String })
  protected value!: any;

  @Prop({ type: Boolean, default: () => false })
  protected filteredIsActive!: boolean;

  @Prop({ type: Boolean, default: () => false })
  protected hideFilter!: boolean;

  @Prop({ type: String, default: () => "" })
  protected iconClass!: string;

  @Prop({ type: Boolean, default: () => false })
  protected focus!: boolean;

  protected onChange(event: Event): void {
    this.$emit("input", (event.target as HTMLInputElement).value);
  }

  public created(): void {
    if (this.focus) {
      this.elFocus();
      this.focused = true;
    }
  }

  public elFocus() {
    if (this.focused) return;
    this.$nextTick(() => {
      this.$refs.searchInput.focus();
    });
  }

  public onFocus() {
    this.focused = true;
  }

  public onBlur() {
    this.focused = false;
  }
}
